<script>
import axios from 'axios'
import { ref, computed } from "vue";
import { useCookies } from "vue3-cookies";
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";

export default {
  name: "companySalesRoute",
  props: [
    'ID',
    'type',
    'data',
  ],
  components:{
   
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();   
    let tk = 'Bearer '+cookies.get('tk')
    let searchTerm = ref('')
    let qSearch = ''
    let countries = []


    const fullSearch = async () =>
    {
      axios.get(`${serverConfig.EP}customers/search/full/text`,
        { 
          params: { 
            q: qSearch,
           
          },
          headers: { 'authorization':tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          countries = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }


    const searchCountries = computed(() => {
      if (searchTerm.value === '') {
        return []
      }
      let matches = 0
      return countries.filter(country => {
        if (country.name.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
          matches++
          return country
        }
      })
    });
    const selectCountry = (country) => {
      selectedCountry.value = country
      searchTerm.value = ''
    }
    let selectedCountry = ref('')

    return { 
      fullSearch,
      searchTerm,
      searchCountries,
      selectCountry,
      selectedCountry,
      active, 
      toggleActive, 
      tk 
    };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      fileStorage:[],
      uploadValue:0,
      fileList:null,
      fileData:[],
      file:'',
      bucket:'',
      dataEdit:{},
      qSearch:'',
      resultsList:[],
      slList:[],
      
    }
  },
  mounted(){
    if (this.ID) {
      this.getSLByComp(this.ID)
    }
  },
  methods:{
    
    async fullSearch2(){
      axios.get(`${this.UrlServer}sales-route/search/full/text`,
        { 
          params: { 
            q: this.qSearch,
          },
          headers: { 'authorization':this.tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          
          this.resultsList = response.data.filter(function(el){
            return el.tipo != 'child'
          })

        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async getSLByComp(id){
      axios.get(`${this.UrlServer}sales-route/srlcompany/${id}`,
        { 
          headers: { 'authorization':this.tk} 
        }
      )
      .then((response) => {
        console.log("Get Salesroute")
        console.log(response.data)
        if (response.data)  {
          if (response.data.length>0){
            this.slList = response.data
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    selectCustomer(params){
      this.addCompanySL(params)
    },
    checkIFDataInList(params){
    
     if (this.slList.length) {
        let data = this.slList.filter(function(el){
          return el.Codice == params.Codice && el.CodMarca == params.CodMarca
        })
        if (data){
          return true
        } else {
          return false
        }
     } else {
      return false
     }
    },
    addCompanySL(params){
      
      if (this.checkIFDataInList(params) == false ){
      
        let data = {
            companyID: this.ID,
            userId:this.data.userId,
            salesRouteID: params._id,
            Codice: params.Codice,
            CodMarca: params.CodMarca,
        }
       
        axios.post(`${this.UrlServer}sales-route/add-company`, data, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data){
              
              Swal.fire( this.$t("t-msg-slcompany") , this.$t("t-msg-slcompany-content"), "success");
              this.getSLByComp(this.ID)
            }
          
          });

      } else {
        Swal.fire({
              title: "Operazione non ammessa!",
              text: "Il dato è già stato inserito.",
              icon: "warning"
            });
      }
        

   },
   removeCompanySL(id){
      let self = this
      Swal.fire({
        title: "Sicuri di proseguire?",
        text: "Proseguendo l'operazione il dato sarà rimosso definitivamente!",
        icon: "warning",
        showCancelButton: true,
        
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sì, rimuovi associazione!",
        cancelButtonText: "No, annulla operazione",
      }).then((result) => {
        if (result.isConfirmed) {

          axios.delete(`${this.UrlServer}sales-route/slrcompany/${id}`, {headers: { authorization:this.tk}} )
          .then((response)=>{
            
            if (response.data){
              Swal.fire({
              title: "Rimosso!",
              text: "Il dato è stato rimosso con successo!",
              icon: "success"
            });
              
           
            }
          
          })
          .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
              console.log(error)
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });

          self.getSLByComp(self.ID)
        }
      });

   },
   removeItem(){
        let data = {
            parent:null,
            detailCompany:null
        }
       
        axios.put(`${this.UrlServer}users/${this.ID}`, data, {headers: { authorization:this.tk}} )
          .then(()=>{
            document.getElementById('detail-company').innerHTML =''
            this.resultsList = []
          })
          .catch((error)=>{
            
            if (error == "Error: Request failed with status code 401")
            {
              console.log(error)
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
    openModal(){
        document.getElementById("editmodal").click();
    },
    closeModal(){
      document.getElementById("close-modal").click();
    },  
  }
};
</script>
<template>
<div class="row background-c-primary h_120">
  <div class="col-md-12 col-lg-6">
    <div class="mt-20 mb-50">
      <div class="modal-header bg-dark p-3 pl-5 pr-5">
        <h5  id="searchUserModalLabel">
          Associa canali di vendita ad anagrafica azienda
        </h5>           
      </div>
      <div class="modal-body">
        <div class="mb-3"> 
          <input
            type="text"
            id="search"
            v-model="qSearch"
            placeholder="Digita qui termine di ricerca..."
            class="form-control-search p-3 mb-0.5 w-full border border-blue-300 rounded"
            @input="fullSearch2()"
            >   
        </div>
       
          <div class="mb-3 overflow-y-scroll-200" >
            <ul                
              v-if="resultsList.length > 0"
              class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10 "
            >                
              <li
                v-for="item in resultsList"
                :key="item._id"
                @click="selectCustomer(item)"
                class="cursor-pointer hover:bg-gray-100 p-1"
                >
                Marca: <strong>{{ item.CodMarca }}</strong>   Codice: <strong>{{ item.Codice }}</strong>                 
                <span class="float-right bx bx-plus"> seleziona</span>
              </li>
            </ul>
          </div>
      </div>  
    </div>
  </div>
  <div class="col-md-6 col-lg-6">
    <div class="mt-20 mb-50">
      <div class="modal-header bg-dark p-3 pl-5 pr-5">
        <h5  id="searchUserModalLabel">Canali associati</h5>      
      </div>
      <div class="modal-body">
        <div class="mb-3 overflow-y-scroll-200" >
          
                            <ul
                              
                              v-if="slList.length > 0"
                              class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10 "
                            >
                              
                              <li
                                  v-for="item in slList"
                                  :key="item._id"
                                 
                                  class="cursor-pointer hover:bg-gray-100 p-1"
                                 
                              >
                                Marca: <strong>{{ item.CodMarca }}</strong>   Codice: <strong>{{ item.Codice }}</strong>  
                               
                                <span class="float-right bx bx-trash-alt"  @click="removeCompanySL(item._id)"> Rimuovi</span>
                              </li>
                            </ul>
                            
                        </div>
      </div>
    </div>
  </div>
<!--
<div class="col-md-12 col-lg-6">
  <div class="mt-20 mb-50">
        <div class="modal-content">
                  <div class="modal-header bg-dark p-3 pl-5 pr-5">
                    <h5  id="searchUserModalLabel">
                     Associa canali di vendita ad anagrafica azienda
                    </h5>
                   
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input type="text" id="edtidfield1" class="form-control" placeholder="ID" readonly />
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-lg-6">
                          <div class="mb-3">
                           
                              <input
                                type="text"
                                id="search"
                                v-model="qSearch"
                                placeholder="Digita qui termine di ricerca..."
                                class="form-control-search p-3 mb-0.5 w-full border border-blue-300 rounded"
                                @input="fullSearch2()"
                              >   
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-6">
                          <div class="mb-3 overflow-y-scroll-200" >
                            <ul
                              
                              v-if="resultsList.length > 0"
                              class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10 "
                            >
                              
                              <li
                                  v-for="item in resultsList"
                                  :key="item._id"
                                  @click="selectCustomer(item)"
                                  class="cursor-pointer hover:bg-gray-100 p-1"
                                 
                              >
                                Marca: <strong>{{ item.CodMarca }}</strong>   Codice: <strong>{{ item.Codice }}</strong>  
                               
                                <span class="float-right bx bx-plus"> seleziona</span>
                              </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
          </form>
        </div>
    </div>
</div>
<div class="col-md-12 col-lg-6">
  <div class="mt-20">
        <div class="modal-content">
          <div class="modal-header bg-dark p-3 pl-5 pr-5">
            <h5  id="searchUserModalLabel">Canali associati</h5>
                   
          </div>

                    <div class="modal-body">

                      <div class="row">
                        <div class="col-md-12 col-lg-12">
                         
                        </div>
                    <div class="col-md-12 col-lg-12">
                          <div class="mb-3 overflow-y-scroll-200" >
                            <ul
                              
                              v-if="slList.length > 0"
                              class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10 "
                            >
                              
                              <li
                                  v-for="item in slList"
                                  :key="item._id"
                                 
                                  class="cursor-pointer hover:bg-gray-100 p-1"
                                 
                              >
                                Marca: <strong>{{ item.CodMarca }}</strong>   Codice: <strong>{{ item.Codice }}</strong>  
                               
                                <span class="float-right bx bx-trash-alt"  @click="removeCompanySL(item._id)"> Rimuovi</span>
                              </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
      </div>
</div>
 -->
</div>

</template>



