<script>
import axios from 'axios'
import { ref, computed } from "vue";
import { useCookies } from "vue3-cookies";
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";

export default {
  name: "companySearchModel",
  props: [
    'ID',
    'type',
    'data',
  ],
  components:{
   
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();   
    let tk = 'Bearer '+cookies.get('tk')
    let searchTerm = ref('')
    let qSearch = ''
    let countries = []


    const fullSearch = async () =>
    {
      axios.get(`${serverConfig.EP}customers/search/full/text`,
        { 
          params: { 
            q: qSearch,
           
          },
          headers: { 'authorization':tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          countries = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }


    const searchCountries = computed(() => {
      if (searchTerm.value === '') {
        return []
      }
      let matches = 0
      return countries.filter(country => {
        if (country.name.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
          matches++
          return country
        }
      })
    });
    const selectCountry = (country) => {
      selectedCountry.value = country
      searchTerm.value = ''
    }
    let selectedCountry = ref('')

    return { 
      fullSearch,
      searchTerm,
      searchCountries,
      selectCountry,
      selectedCountry,
      active, 
      toggleActive, 
      tk 
    };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      fileStorage:[],
      uploadValue:0,
      fileList:null,
      fileData:[],
      file:'',
      bucket:'',
      dataEdit:{},
      qSearch:'',
      resultsList:[],
      
    }
  },
  mounted(){
   

  },
  methods:{
    async fullSearch2(){
      axios.get(`${this.UrlServer}users/search/full/text`,
        { 
          params: { 
            q: this.qSearch,
          },
          headers: { 'authorization':this.tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          this.resultsList = response.data.filter(function(el){
            return el.registryType == 'main'
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    selectCustomer(params){
      this.updateItem(params)
    },
    updateItem(params){
        let data = {
            parent:params._id,
            detailCompany:{
                companyId:params._id,
                companyName:params.ragSoc
            }
        }
       
        axios.put(`${this.UrlServer}users/${this.ID}`, data, {headers: { authorization:this.tk}} )
          .then(()=>{
            document.getElementById('detail-company').innerHTML = params.ragSoc
          
          })
          .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
              console.log(error)
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
      removeItem(){
        let data = {
            parent:null,
            detailCompany:null
        }
       
        axios.put(`${this.UrlServer}users/${this.ID}`, data, {headers: { authorization:this.tk}} )
          .then(()=>{
            document.getElementById('detail-company').innerHTML =''
            this.resultsList = []
          })
          .catch((error)=>{
            
            if (error == "Error: Request failed with status code 401")
            {
              console.log(error)
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
    openModal(){
        document.getElementById("editmodal").click();
    },
    closeModal(){
      document.getElementById("close-modal").click();
    },  
  }
};
</script>
<template>
<div>
<!--
  <div class="row background-c-primary h_120" >
    <div class="col-md-12 col-lg-12">
      <div class="mb-3">

        <label for="username" class="form-label"  v-if="!data.detailCompany">ANCORA NESSUNA AZIENDA ASSOCIATA</label>
        <label for="username" class="form-label"  v-if="data.detailCompany">AZIENDA AFFILIATA</label>
        <h3 id="detail-company" v-if="data.detailCompany">{{ data.detailCompany.companyName }}   </h3>
        <a href="javascript:void(0)" @click="removeItem"  v-if="data.detailCompany">Rimuovi associazione</a>
        
        <div class="mt-20">

            <div class="modal-content">
                      <div class="modal-header bg-dark p-3 pl-5 pr-5">
                        <h5  id="searchUserModalLabel">
                        Associa anagrafica azienda di riferimento al profilo utente
                        </h5>
                      
                      </div>
                    -->
                     
                          <div class="row">
                            <div class="col-md-12 col-lg-12">
                              <div class="mb-3">
                              
                                  <input
                                    type="text"
                                    id="search"
                                    v-model="qSearch"
                                    placeholder="Digita qui termine di ricerca..."
                                    class="form-control-search p-3 mb-0.5 w-full border border-blue-300 rounded"
                                    @input="fullSearch2()"
                                  >             
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12 col-lg-12">
                                  <div class="mb-3">
                                    <ul
                                      
                                      v-if="resultsList.length > 0"
                                      class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
                                    >
                                      
                                      <li
                                          v-for="item in resultsList"
                                          :key="item._id"
                                          @click="selectCustomer(item)"
                                          class="cursor-pointer hover:bg-gray-100 p-1"
                                        
                                      >
                                        {{ item.ragSoc }} {{ item.partitaIva }}  
                                        <span class="float-right bx bx-plus"> seleziona</span>
                                      </li>
                                    </ul>

                                </div>
                            </div>
                          </div>
                   
<!--
            </div>



            
        </div>

    </div>
  </div>
</div>
-->
</div>
</template>



