<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
 import { useStore } from 'vuex'
import serverConfig   from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import { ref } from "vue";
import { useCookies } from "vue3-cookies";
import { mapGetters,mapActions} from "vuex";
import { computed } from 'vue'
//import Multiselect from "@vueform/multiselect";
//import "@vueform/multiselect/themes/default.css";
import companySearchModel from "@/components/widgets/companySearchModel";
import companySalesRoute from "@/components/widgets/companySalesRoute";
export default {
  name: "usersTable",
  props: [
    'data',
    'type',
    'title',
  ],
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();
    const store = useStore();

    let tk = 'Bearer '+ localStorage.getItem('tk')
    let gettersSettingRole = computed(() => store.getters["settings/getSettingsRole"])
    let gettersSettingArea = computed(() => store.getters["settings/getSettingsArea"])

    return { active, toggleActive,tk,cookies, v$: useVuelidate(), gettersSettingRole, gettersSettingArea};
  },
  data() {
	return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        page: 1,
        perPage: 100,
        sortRes:'asc',
        value: 'All',
        pages: [],
        paginated:{},
        select:'all',
        search:'',
        customerList:[],
        n_results:0,
        dataEdit:{},
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        roles:false,
        inviteNUser:{
          email:"",
          message:"",
          status:"",
          role:"",
          roles_permissions:{
            area:"",
            role:""
          }
        },
        customer:{
          status:'init',
          code:'',
          sigla:'',
          tipoSoggetto:'',
          ragSoc:'aggiungi `Ragione sociale`',
          codiceFiscale:'aggiungi `Codice Fiscale`',
          partitaIva:'aggiungi `Partita Iva`',
          codiceAteco:'aggiungi `Codice Ateco`',
          nRea:'',
          artigiano:'No',
          email:'',
          telefono:'aggiungi `Telefono`',
          indirizzo:'aggiungi `Ragione sociale`',
          regimeFiscale:'ordinario',
          regimeContabile:'ordinario',

        },
        items: [{
            text: "Gestione utenti",
            href: "/",
          },
          {
            text: "Lista utenti",
            active: true,
          },
        ],
        optionsArea:[
          {value:null, label:'Seleziona Area'},
          {value:'administrative', label:'Area Amministrazione'},
          {value:'commercial-office', label:'Area commerciale'},
          {value:'commercial', label:'Operatori area commerciale (venditori)'},
          {value:'technical-office', label:'Area tecnica'},
          {value:'technical', label:'Operatori area tecnica (capisquadra, installatori, operai vari)'},
          {value:'all', label:'Accesso completo (amministratori)'},
        ],
        optionsRoles:[],
    }
	},
    validations: {
      inviteNUser: {
        email: {
          required: helpers.withMessage("Email obbligatoria", required),
          email: helpers.withMessage("Per favore, inserisci una email valida", email),
        },
        status: {
          required: helpers.withMessage("Stato obbligatorio", required),
        },
        roles_permissions:{
          area: {
            required: helpers.withMessage("Area obbligatoria", required),
          },
          role: {
            required: helpers.withMessage("Ruolo obbligatorio", required),
          },
        }

      },
    },
	computed:{ 
        ...mapGetters('settings', ['getSettingsRole','getSettingsArea']),
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
        displayedPosts() {
            return this.paginate(this.customerList);
        },
	},
  mounted(){
    this.getSearchList()
    this.getRoles()
  },
	methods:{
    ...mapActions('layout', ['changeLayoutType', ]),
      axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }                
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
    },
    roundTo(value, decimalpositions)
    {
      var i = value * Math.pow(10,decimalpositions);
      i = Math.round(i);
      return i / Math.pow(10,decimalpositions);
    },
    replName(val,el){
      if (val.includes(el)){
        return val.replaceAll(el,' ')
      } else {
        return val
      }
    },
    formatKb(val){
      if (val){
        return this.roundTo(val / 1024, 1)
      } else {
        return ''
      }
    },
    getParent(){
      this.getList()
    },
    resetQuery(){
      this.getSearchList()
    },
    changeAreaValue(event,par){
      let roles = []
      this.dataEdit.role = ''
      this.optionsRoles=[]
      let value =  false
      if (par=='event') {
        value =  event.target.value
      } else {
        value = event
      }
      if (value) {
        for (let item of this.roles){
          if (item.area == value && item.role != 'superuser') {
            roles.push({
              "value": item.role,
              "label": item.name,
              "_id": item._id,
              "code":item.code ,
              "name": item.name,
              "key": item.key,
              "permissions":item.permissions,
              "role": item.role,
              "pages": item.pages,
              "layout": item.layout,
              "area":item.area,
            })

          }
        }
        this.optionsRoles = roles
      }
    },
    changeArea(event){
      let roles = []
      this.dataEdit.role = ''
      this.optionsRoles=[]

      if (event.target.value) {
        for (let item of this.roles){
          if (item.area == event.target.value && item.role != 'superuser') {
            roles.push({
              "value": item.role,
              "label": item.name,
              "_id": item._id,
              "code":item.code ,
              "name": item.name,
              "key": item.key,
              "permissions":item.permissions,
              "role": item.role,
              "pages": item.pages,
              "layout": item.layout,
              "area":item.area,              
            })

          }
        }
        this.optionsRoles = roles
      }
    },
    changeRole(event){
     
      this.dataEdit.role =  event.target.value
      for (let item of this.roles){
       
        if (item.role == event.target.value){
          this.dataEdit.roles_permissions = {
            "code": item.code,
            "name": item.name,
            "key": item.key,
            "permissions":[],
            "role": item.role,
            "pages": item.pages,
            "layout": item.layout,
            "area": item.area,
          }          
        }
      
      }

    },
    getRoles(){
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}settings/options/roles-permissions`, 
          { headers: { 'authorization':this.tk}}
       ).then(response=>{
          this.roles = response.data;
      })
    },
    getList(){
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}users/per-page/all`, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        this.customerList = response.data.results.data;
        this.paginated = response.data.results
        this.n_results = response.data.n_results
      });
    },

    getSearchList(){
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}users/search-per-page/`, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        this.customerList = response.data.results.data;
        this.paginated = response.data.results
        this.n_results = response.data.n_results
      });
    },
    paginateAct(pg){
        this.page = pg
        this.getList()
    },
    splitStr(str,car){
        if (str){
          if(str.includes(car)==true) {
            let val =  str.split(car)
            return `${val[0]} ${val[1]}`
          } else{
            return str
          }
        } else {
          return ''
        }
    },
      editdata(data) {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}users/${data._id}`, 
          { headers: { 'authorization':this.tk}}
        ).then(response=>{
          this.dataEdit = response.data
          
          if (this.dataEdit.roles_permissions)
          {
          
            if (Object.hasOwn(this.dataEdit.roles_permissions,'area')==true){
              this.changeAreaValue(this.dataEdit.roles_permissions.area,'value')
            } else {
              this.dataEdit.roles_permissions = {
              code: 11,
              name: "Venditore",
              key: "dealer",
              permissions:[],
              role: "dealer",
              pages:[],
              layout: "horizontal",
              area: "commercial",
            }
            this.changeAreaValue("commercial",'value')     
            }
            
          } else {
            this.dataEdit.roles_permissions = {
              code: 11,
              name: "Venditore",
              key: "dealer",
              permissions:[],
              role: "dealer",
              pages:[],
              layout: "horizontal",
              area: "commercial",
            }
            this.changeAreaValue(this.dataEdit.roles_permissions.area,'value')               
          }

        });
       
      },
      initItem(){
        this.submitted = true;
        this.v$.$touch()
      
        this.inviteNUser.app_name = 'Impiantino - Agenzia Sanna & Piras'
        this.inviteNUser.username = this.inviteNUser.email
        
        this.inviteNUser.roles_permissions = {
          code: 11,
          name: "Venditore",
          key: "dealer",
          permissions:[],
          role: "dealer",
          pages:[],
          layout: "horizontal",
          area: "commercial",
        }

        if (this.inviteNUser.email!=''&&this.inviteNUser.status!='' && this.inviteNUser.roles_permissions.role!=''){
          this.axiosInterceptor()
          axios.post(`${this.UrlServer}users/invite-a-new-user`, this.inviteNUser, {headers: { authorization:this.tk}} )
          .then(()=>{
            
            document.getElementById("closemodal").click();
            Swal.fire(  this.$t("t-msg-invited") , this.$t("t-msg-invited-content"), "success");
            this.getList()
          });
        } else {
          Swal.fire(  this.$t("t-msg-invited-no-data") , this.$t("t-msg-invited-content-no-data"), "warning");
        }

      },
      updatedata() {
        let dealerRole = this.roles.filter(function(el){
          return el.key == 'dealer'
        })
          if (this.dataEdit.roles_permissions)
          {
          
            if (Object.hasOwn(this.dataEdit.roles_permissions,'area')==true){
              this.changeAreaValue(this.dataEdit.roles_permissions.area,'value')
            } else {
              this.dataEdit.roles_permissions = dealerRole[0]
              /*
              this.dataEdit.roles_permissions = {
              code: 11,
              name: "Venditore",
              key: "dealer",
              permissions:[],
              role: "dealer",
              pages:[],
              layout: "horizontal",
              area: "commercial",
              }
              */
              this.changeAreaValue("commercial",'value')     
            }
            
          } else {
            this.dataEdit.roles_permissions = dealerRole[0]
            this.changeAreaValue( "commercial",'value')               
          }

        this.dataEdit.role = this.dataEdit.roles_permissions.role
        this.dataEdit.area = this.dataEdit.roles_permissions.area
        
        if (this.dataEdit.role!=''){
          this.axiosInterceptor()     
          axios.put(`${this.UrlServer}users/${this.dataEdit._id}`, this.dataEdit, 
          {headers: { authorization:this.tk}} )
            .then((response) => {
              if (response.data.modifiedCount==1) {
                this.getList()
                Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
              }
            })
        } else {
          this.dataEdit.status = 'blocked'
          Swal.fire({
              title:"Ruolo utente non indicato",
              text:"Attenzione: ad ogni utente deve essere necessariamente associato un ruolo. Impostare il ruolo, per favore.",
              icon:"warning",
              showCancelButton:false,
              showConfirmButton:true,
            })
        }

      },
      deletedata(event) {
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
        ).then((result) => {
          if (result.value) {
            this.customerList.splice(this.customerList.indexOf(event), 1);
            try {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}users/${event._id}`,{headers: { authorization:this.tk}})
                .then(() => {
                  
                  this.getList()
                  Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                });
            }catch(error){
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          }
        });
      },
      async alertSessionExp() {
        this.clearSessionStorage()

      },
      goTo(id){
        this.$router.push('/user/'+id)
      }
    
  },
  components: {
   //Multiselect,
    companySearchModel,
    companySalesRoute,
  },
};
</script>
<template>
  <div class="back-grey">
    <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <a href="#sendmailmodal" data-bs-toggle="modal" class="btn btn-success add-btn" @click="getRoles()">
                        <i class="ri-mail-send-line"></i>
                        Invita nuovo utente
                    </a>
                  </div>
                </div>
                <div class="col-lg-10 col-md-12">
                  <div class="">
                    <div class="search-box ms-2">
                      <input type="text" class="form-control" placeholder="Cerca utente..." v-model="search" @input="resetQuery()" />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header  mb-50">
              <div class="row align-items-center mb-50">
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Utenti registrati
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
          <div class="card-body">
            <div class="table-card mb-1">
                <div class="row thRow  ">

                    <div class="col-lg-2 col-md-12">
                        Email
                    </div>
                    <div class="col-lg-2 col-md-12">
                        Nome e Cognome
                    </div>
                    <div class="col-lg-2 col-md-12">
                    Username
                    </div>
                    <div class="col-lg-2 col-md-12">
                        Ruolo
                    </div>
                    <div class="col-lg-1 col-md-12">
                        Stato
                    </div>
                    <div class="col-lg-1 col-md-12 ">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow  " v-for="(item, index) of customerList" :key="index">

                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline ">
                            {{ item.email }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline ">
                        {{ item.firstName }}   {{ item.lastName }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline ">
                            {{ item.username }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        <div class="list-inline ">    
                            <template v-if="item.roles_permissions">
                              <template v-if="item.roles_permissions.name">
                                <strong>{{ item.roles_permissions.name }}</strong>
                              </template>
                              <template v-else>N.D.</template>
                            </template>
                            <template v-else>N.D.</template>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12">
                      <template v-if="item.status=='active'">
                        <span class="badge text-uppercase badge-soft-success">Attivo</span>
                      </template>
                      <template v-else>
                        <span class="badge text-uppercase badge-soft-danger">Bloccato</span>
                      </template>
                    </div>
                    <div class="col-lg-2 col-md-12">
                        <ul class="list-inline ">
                            <!--<li class="list-inline-item " data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                              <a href="#editmodal" data-bs-toggle="modal"
                                class="text-primary d-inline-block edit-item-btn" @click="goTo(item._id)">
                                <i class="ri-pencil-fill fs-16"></i>
                              </a>
                            </li>-->
                            <li class="list-inline-item " title="Modifica">
                              <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="goTo(item._id)">
                                <i class="ri-pencil-fill fs-16"></i>
                              </a>
                            </li>
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a class="text-danger d-inline-block remove-item-btn" @click="deletedata(item)"><i class="ri-delete-bin-5-fill fs-16"></i></a>
                            </li>
                        </ul>
                    </div>
                    

                </div>
            </div>
            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3">        
                    <div class="col-lg-2 col-md-12 ">
                        <label class="pt-2">Risultati per pagina:</label>
                        <div class="pl-10 pr-20">
                        
                        <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                        <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                    </div>
              </div>
            </div>

            <div class="modal fade" id="sendmailmodal"  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticSendEmailModal">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      {{ $t("t-customers-sendemail") }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form class="needs-validation" @submit.prevent="initItem" id="sendemail">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>
                        Invito inoltrato con successo.</b-alert>

                    <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                    </b-alert>
                    <!--<div v-if="notification.message" :class="'alert ' + notification.type">
                        {{ notification.message }}
                        </div>
                      -->
                      <div class="mb-3">                
                        <label for="useremail" class="form-label">Email <span class="text-danger">*</span></label>
                          <input type="email" class="form-control" v-model="inviteNUser.email" id="useremail" :class="{
                      'is-invalid': submitted && v$.inviteNUser.email.$error,
                    }" placeholder="Inserisci indirizzo email">
                        <div v-for="(item, index) in v$.inviteNUser.email.$errors" :key="index" class="invalid-feedback">
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="msg-field" class="form-label">Messaggio di invito</label>
                        <textarea id="msg" class="form-control" v-model="inviteNUser.message" placeholder="Inserisci messaggio di invito"  >
                        </textarea>
                      </div>
                      <div class="mb-3">
                        <label for="status-field" class="form-label">Stato <span class="text-danger">*</span></label>
                        <select class="form-control" :class="{
                      'is-invalid': submitted && v$.inviteNUser.status.$error,
                    }" placeholder="Inserisci stato" data-trigger id="statusfield" v-model="inviteNUser.status">
                          <option value="">Inserisci Stato</option>
                          <option value="blocked">Bloccato</option>
                          <option value="active">Attivo</option>
                          
                        </select>
                        <div v-for="(item, index) in v$.inviteNUser.status.$errors" :key="index" class="invalid-feedback">
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                      </div>    
                      <div class="mb-3">
                        <label for="role-field" class="form-label">Area <span class="text-danger">*</span></label>
                        <select class="form-control" :class="{
                              'is-invalid': submitted && v$.inviteNUser.roles_permissions.area.$error,
                             }" placeholder="Inserisci Area" data-trigger id="rolefield" v-model="inviteNUser.roles_permissions.area"  @change="changeArea($event)">
                          <option value="">Inserisci Area di riferimento</option>
                          <option v-for="item in optionsArea"  :value="item.value" :key="item.value">{{ item.label }}</option>
                        </select>

                      </div>
                      <div class="mb-3">
                        <label for="role-field" class="form-label">Ruolo <span class="text-danger">*</span></label>
                        <select class="form-control" :class="{
                      'is-invalid': submitted && v$.inviteNUser.roles_permissions.role.$error,
                           }" placeholder="Inserisci ruolo" data-trigger id="rolefield" v-model="inviteNUser.roles_permissions.role" >
                          <option value="">Inserisci Ruolo</option>
                          <option v-for="item in optionsRoles"  :value="item.value" :key="item.value">{{ item.label }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="closemodal" class="btn btn-light"
                          data-bs-dismiss="modal">Chiudi</button>
                        <button type="submit" class="btn btn-info" >
                          <i class="ri-mail-send-fill me-2 align-bottom"></i>
                          Invia invito
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
<!-- MODAL edit anagrafica utente -->
            <div class="modal fade" id="editmodal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticEditModal">
              <div class="modal-dialog  modal-fullscreen  modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3 pl-5 pr-5">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Aggiorna anagrafica utente
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal" style="">
                    
                    </button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input type="text" id="edtidfield1" class="form-control" placeholder="ID" readonly />
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <label for="username" class="form-label">Username</label>
                              <input type="text" id="username" v-model="dataEdit.username" class="form-control" placeholder="Inserisci Username" readonly="readonly" />
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <label for="firstname" class="form-label">Nome</label>
                              <input type="text" id="firstname" v-model="dataEdit.firstname" class="form-control" placeholder="Inserisci Nome" />
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <label for="lastname" class="form-label">Cognome</label>
                              <input type="text" id="lastname" v-model="dataEdit.lastname" class="form-control" placeholder="Inserisci Cognome" />
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <label for="email-field" class="form-label">Email</label>
                          <div class="input-group mb-3">
                            <input type="email" id="edtemail" v-model="dataEdit.email" class="form-control" placeholder="Inserisci Email" readonly />
                            <div class="input-group-append">
                              <span class="input-group-text" ><i class="ri-mail-unread-line"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <label for="phone-field" class="form-label">Telefono</label>
                          <div class="input-group mb-3">
                              <input type="text" id="telefono" v-model="dataEdit.phone" class="form-control" placeholder="Inserisci Telefono"  />
                              <div class="input-group-append">
                              <span class="input-group-text ri-phone-line" ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <label for="code-field" class="form-label">Codice Utente</label>
                          <div class="input-group mb-1">
                            <input type="text" id="code" v-model="dataEdit.code" class="form-control" placeholder="Inserisci Codice utente" readonly />
                            <div class="input-group-append">
                              <span class="input-group-text " ><i class="bx bxs-face mb-1"></i></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row background-usergroup mt-2" >
                        <div class="col-md-12 col-lg-4">
                          <label for="role-field" class="form-label">Gruppo Anagrafica </label>
                          <div class="input-group mb-3">
                            <select v-model="dataEdit.userGroup" class="form-select mb-3">
                              <option value="main">Azienda madre </option>
                              <option value="affiliate">Azienda affiliata </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-6">
                          <div class="input-group background-color-white" >
                            Il gruppo anagrafica può essere:
                           <ul>
                             <li>'<strong>Azienda madre</strong>': quando il profilo è inquadrabile nell'ambito delle attività dell'azienda principale, ovvero tutti quelli alle dipendenze di questa.</li>
                             <li>'<strong>Azienda affiliata </strong>': quando il profilo è riconducibile ad un'azienda affiliata o di un utente che opera per conto e alle dipendenze di questa</li>
                            </ul>
                           <br>                
                          </div>
                        </div>
                      </div>  
                   
                      <div class="row background-affiliate mt-1" v-if="dataEdit.userGroup=='affiliate'">
                        <div class="col-md-12 col-lg-3">
                          <label for="role-field" class="form-label">Tipologia di profilo anagrafica </label>
                          <div class="input-group mb-3">
                            <select v-model="dataEdit.registryType" class="form-select mb-3">
                              <option value="main">Anagrafica principale dell'azienda affiliata </option>
                              <option value="associated">Utente associato ad azienda affiliata</option>
                            </select>
                          </div>
                        
                        </div>
                        <div class="col-md-12 col-lg-3">
                          
                          <div class="input-group background-color-white" >
                           
                              La Tipologia di profilo può essere:
                              <ul>
                                <li>'<strong>Anagrafica principale dell'azienda affiliata</strong>': quando il profilo coincide con quello dell'azienda affiliata</li>
                                <li>'<strong>Utente associato</strong>': quando il profilo è di un utente che opera per conto e alle dipendenza dell'azienda affiliata</li>
                              </ul>
                              <br>
                              
                                             
                          </div>
                        </div>
                        <!-- -->
                        <div class="col-md-12 col-lg-2 mb-50">
                          <template v-if="dataEdit.roles_permissions && dataEdit.roles_permissions.role=='superuser'">
                            <label class="alert alert-info">Anagrafica amministrazione piattaforma</label>
                          </template>
                          <template v-else>
                            <label for="area-field" class="form-label">Area</label>
                            <div class="input-group mb-3">    
                                <select class="form-select" v-model="dataEdit.roles_permissions.area" @change="changeArea($event)">
                                  <option v-for="item in optionsArea"  :value="item.value" :key="item.value">{{ item.label }}</option>
                                </select>
                              
                            </div>
                          </template>
                        </div>                        
                        <div class="col-md-12 col-lg-2">
                          <template v-if="dataEdit.roles_permissions && dataEdit.roles_permissions.role=='superuser'">
                            <label class="alert alert-info"><strong>Utente con requisiti da super utente</strong></label>
                          </template>
                          <template v-else>
                          <label for="role-field" class="form-label">Ruolo*</label>
                          <div class="input-group mb-3">
                            <select class="form-select" v-model="dataEdit.roles_permissions.role" @change="changeRole($event)" >
                                <option v-for="item in optionsRoles"  :value="item.value" :key="item.value">{{ item.label }}</option>
                              </select>
                          </div>
                        </template>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <template v-if="dataEdit.roles_permissions && dataEdit.roles_permissions.role=='superuser'">
                           
                          </template>
                          <template v-else>
                            <label for="status-field" class="form-label">Stato profilo</label>
                            <div class="input-group mb-3">
                                <select class="form-select" v-model="dataEdit.status">
                                  <option value="blocked">Bloccato</option>
                                  <option value="active">Attivo</option>
                                </select>
                            </div>
                          </template>
                         
                        </div>
                        <!-- -->
                      </div> 
                      <div class="row background-c-primary" v-if="dataEdit.userGroup=='affiliate' && dataEdit.registryType=='main'">
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <label for="username" class="form-label">Ragione sociale</label>
                              <input type="text" v-model="dataEdit.ragSoc" class="form-control" placeholder="Inserisci Ragione sociale"  />
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <label for="firstname" class="form-label">Partita Iva</label>
                              <input type="text"  v-model="dataEdit.partitaIva" class="form-control" placeholder="Inserisci Partita Iva" />
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <label for="lastname" class="form-label">Codice destinatario</label>
                              <input type="text" id="lastname" v-model="dataEdit.codeDest" class="form-control" placeholder="Codice destinatario" />
                          </div>
                        </div>
                        
                        <div class="col-md-12 col-lg-2">
                          <label for="email-field" class="form-label">Email</label>
                          <div class="input-group mb-3">
                            <input type="email" v-model="dataEdit.emailAzienda" class="form-control" placeholder="Inserisci Email"  />
                            <div class="input-group-append">
                              <span class="input-group-text" ><i class="ri-mail-unread-line"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <label for="email-field" class="form-label">PEC</label>
                          <div class="input-group mb-3">
                            <input type="email" v-model="dataEdit.pec" class="form-control" placeholder="Inserisci PEC"  />
                            <div class="input-group-append">
                              <span class="input-group-text" ><i class="ri-mail-unread-line"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <label for="code-field" class="form-label">Indirizzo</label>
                          <div class="input-group mb-1">
                            <input type="text" v-model="dataEdit.indirizzo" class="form-control" placeholder="Inserisci Indirizzo"  />
                            <div class="input-group-append">
                              <span class="input-group-text " ><i class="bx bxs-home mb-1"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-2">
                          <label for="code-field" class="form-label">Città</label>
                          <div class="input-group mb-1">
                            <input type="text" v-model="dataEdit.citta" class="form-control" placeholder="Inserisci Città"  />
                            <div class="input-group-append">
                              <span class="input-group-text " ><i class="bx bxs-city mb-1"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-1">
                          <label for="code-field" class="form-label">Cap</label>
                          <div class="input-group mb-1">
                            <input type="text" v-model="dataEdit.cap" class="form-control" placeholder="Cap"  />
                            <div class="input-group-append">
                              <span class="input-group-text " >C.A.P</span>
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div class="row background-c-primary h_120 border-grey-f2f7">
                        <companySalesRoute :ID="dataEdit._id" :data="dataEdit" type="usersTable"></companySalesRoute>
                      </div>                                             
                      <div class="row background-c-primary h_120" v-if="dataEdit.userGroup=='affiliate' && dataEdit.registryType=='associated'">
                        <div class="col-md-12 col-lg-6">
                          <div class="mb-3">
                           
                              <companySearchModel :ID="dataEdit._id" :data="dataEdit" type="usersTable" v-on:getParent="getParent()"></companySearchModel>
                            
                            
                          </div>
                          
                        </div>
                      </div>    
                    
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="edtclosemodal" class="btn btn-light" data-bs-dismiss="modal">
                          Chiudi
                        </button>
                        <button type="button" class="btn btn-success" @click="updatedata">
                          Aggiorna
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
<!-- / MODAL edit anagrafica utente -->
          </div>
        </div>
  </div>
</template>